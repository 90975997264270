<template>
  <div>    
    <CRow>
      <CCol col="12" sm="6" lg="3">
        <CWidgetIcon
          :header="'€' + this.totalSales"
          text="Vendas"
          color="primary"
        >
          <CIcon name="cil-euro" width="24"/>
        </CWidgetIcon>
      </CCol>
      <CCol col="12" sm="6" lg="3">
        <CWidgetIcon
          :header="this.totalSalePendente"
          text="Pendente"
          color="warning"
        >
          <CIcon name="cil-drop" width="24"/>
        </CWidgetIcon>
      </CCol>
      <CCol col="12" sm="6" lg="3">
        <CWidgetIcon
          :header="this.totalSalePaid"
          text="Pagas"
          color="success"
        >
          <CIcon name="cil-check" width="24"/>
        </CWidgetIcon>
      </CCol>
      <CCol col="12" sm="6" lg="3">
        <CWidgetIcon
          :header="this.totalSaleDeclined"
          text="Incompletas"
          color="danger"
        >
          <CIcon name="cil-ban" width="24"/>
        </CWidgetIcon>
      </CCol>
    </CRow>

    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            Últimas vendas
          </CCardHeader>
          <CCardBody>
            
            <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableInsurances"
              :fields="tableFields"
              head-color="light"
              sorter
              :items-per-page="10"
              pagination
            >
              <td slot="id" class="text-center" slot-scope="{item}" :columnFilter='{ external: true, lazy: true }'>
                <div>
                  {{item.id}}
                </div>
              </td>
              <td slot="createdAt" slot-scope="{ item }">
                <div>
                  {{ new Date(item.createdAt).toLocaleDateString('pt-PT') }}
                </div>
              </td>
              <td slot="plano" slot-scope="{item}">
                <div>{{item.plan}}</div>
              </td>
              <td slot="qtdPeaple" slot-scope="{item}">
                <div>{{item.people_quantity}} €</div>
              </td>
              <td slot="price" slot-scope="{item}">
                <div>{{item.price}} €</div>
              </td>
              <td slot="payment_status" slot-scope="{item}" :columnFilter='{ external: true, lazy: true }'>
                <CBadge :color="getBadge(item.payment_status)">{{item.payment_status}}</CBadge>
              </td>
              <td slot="action" slot-scope="{item}">
               <CButton block variant="outline" color="primary" @click="getDados(item.id)">Ver</CButton>
              </td>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Seguros"
      size="lg"
      :show.sync="largeModal"
      
    >
      <CRow v-for="insurances in this.modalInsurances" :key="insurances">
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong>Informações </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Número:</label>
                  <p>{{insurances.id}}</p>
                </div>
                <div class="col-md-4">
                  <label>Plano:</label>
                  <p>{{insurances.plan}}</p>
                </div>
                <div class="col-md-4">
                  <label>Pagamento:</label>
                  <p>
                    <CBadge :color="getBadge(insurances.payment_status)">{{insurances.payment_status}}</CBadge>
                  </p>
                </div>

                <div class="col-md-4">
                  <label>Data de subscrição :</label>
                  <p>{{new Date(insurances.createdAt).toLocaleString('pt-PT')}}</p>
                </div>

                <div class="col-md-4">
                  <label>Data inicial:</label>
                  <p>{{new Date(insurances.initial_date).toLocaleDateString('pt-PT')}}</p>
                </div>

                <div class="col-md-4">
                  <label>Data final:</label>
                  <p>{{new Date(insurances.final_date).toLocaleDateString('pt-PT')}}</p>
                </div>

                <div class="col-md-12">
                  <label>Países:</label>
                  <p>{{insurances.countries}}</p>
                </div>

                <div class="col-md-12">
                  <label>Método de Pagamento:</label>
                  <p>{{insurances.payment_method}}</p>
                </div>

                <div class="col-md-8">
                  <label>Easypay payment id:</label>
                  <p>{{insurances.easypay_payment_id}}</p>
                </div>

                <div class="col-md-4">
                  <label>Código de compra:</label>
                  <p>{{insurances.order_code}}</p>
                </div>

                <div class="col-md-6">
                  <label>Apólice enviada:</label>
                  <div>
                    {{insurances.status == 0? 'Não' : 'Sim'}}
                  </div>
                </div>
              </div>
              
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong>Preço:</strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Total:</label>
                  <h4>{{insurances.price}} €</h4>
                </div>
                <div class="col-md-4">
                  <label>Descontos:</label>
                  <p v-if="insurances.promotional">
                    {{insurances.promotional.discount}} %
                  </p>
                  <p v-else>
                    0%
                  </p>
                </div>
                <div class="col-md-4">
                  <label>Código Promocional:</label>
                  <p v-if="insurances.promotional">
                    {{insurances.promotional.code}} (id: {{insurances.promotional.id}})
                  </p>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        
        <CCol sm="12">
          <CCard  v-for="(person, index )  in insurances.persons" :key="index">
            <CCardHeader>
              <strong>Pessoa </strong>{{index +1}} 
              <strong v-if="person.tomador">| Tomador </strong>
              <strong v-if="person.segurado">| Segurado </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Nome:</label>
                  <p>{{person.name}}</p>
                </div>
                <div class="col-md-4">
                  <label>Sobrenome:</label>
                  <p>{{person.surname}}</p>
                </div>
                <div class="col-md-4">
                  <label>NIF:</label>
                  <p>{{person.nif}}</p>
                </div>
                 <div class="col-md-4">
                  <label>Cartão Cidadão:</label>
                  <p>{{toUpper(person.cc)}}</p>
                </div>
                <div class="col-md-4">
                  <label>Bilhete de Identidade:</label>
                  <p>{{person.bi}}</p>
                </div>
                <div class="col-md-4">
                  <label>Passaporte:</label>
                  <p>{{person.passport}} - {{person.passport_country}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Data de nascimento:</label>
                  <p>{{new Date(person.date_of_birth).toLocaleDateString('pt-PT')}}</p>
                </div>
                <div class="col-md-4">
                  <label>Telemóvel:</label>
                  <p>{{person.mobile_number}}</p>
                </div>
                <div class="col-md-4">
                  <label>Email:</label>
                  <p>{{person.email}}</p>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="12">
          <CCard v-for="(adress, index )  in insurances.addresses" :key="index">
            <CCardHeader>
              <strong>Endereço </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Cidade:</label>
                  <p>{{adress.city}}</p>
                </div>
                <div class="col-md-4">
                  <label>País:</label>
                  <p>{{adress.country}}</p>
                </div>
                <div class="col-md-4">
                  <label>Código postal:</label>
                  <p>{{adress.postal_code}}</p>
                </div>
                <div class="col-md-4">
                  <label>Localização:</label>
                  <p>{{adress.location}}</p>
                </div>
                <div class="col-md-8">
                  <label>Endereço:</label>
                  <p>{{adress.line_1}}</p>
                </div>
              </div>
              
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <template #footer>
        <CButton @click="largeModal = false" color="success">Ok</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import InsuranceDataService from "@/services/InsuranceDataService";


export default {
  name: 'Dashboard',
  data () {
    return {
      selected: 'Month',
      tableInsurances: [],
      tableFields: [
        { key: "id", label: "Num. Reserva", _classes: "text-center", sorter: 1, excel: true },
        { key: "createdAt", label: "Data de subscrição", excel: true },
        { key: "order_code", label: "Order code", excel: true },
        { key: "plano", label: "Plano", excel: true },
        { key: "price", label: "Preço", excel: true },
        { key: "payment_status", label: "Pagamento", excel: true },
        { key: "action", label: "Detalhes" },
      ],
      largeModal: false,
      modalInsurances: '',
      totalSales: '0',
      totalSalePendente: '0',
      totalSalePaid: '0',
      totalSaleDeclined: '0',
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  },
  methods: {
    getBadge (status) {
      return status === 'paid' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getDados(id){
      this.modalInsurances = this.tableInsurances.filter(function(event){
        if(event.id == id){
          return event
        }
      })
      this.largeModal = true
    },
    toUpper(str){
        return str ? str.toUpperCase() : ''
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    InsuranceDataService.getTop5()
      .then(response => {
        this.tableInsurances = response.data;
        var sumPrice = 0;
        var sumPaid = 0;
        var sumPending = 0;
        var sumDeclined = 0;
        response.data.forEach(e => {
            sumPrice += parseFloat(e.price)
            if(e.payment_status == 'paid'){
              sumPaid += 1;
            }else if(e.payment_status == 'pending'){
              sumPending += 1
            }else{
              sumDeclined += 1
            }
        });
        this.totalSales = Number(sumPrice).toFixed(2)
        this.totalSalePaid = sumPaid.toString()
        this.totalSalePendente = sumPending.toString()
        this.totalSaleDeclined = sumDeclined.toString()
      })
      .catch(e => {
        alert(e);
      });
  }
}
</script>

<style scope="">
  label{
    font-weight: bold;
  }
</style>